<template>
  <div class="index" v-loading="loading" element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(255, 255, 255, .9)">
    <web-header active-url="/" borderBottom></web-header>
    <div class="index-one">
      <swiper :options="oneSwiperOption" class="index-one-swiper" ref="oneSwiper" v-if="carouselList && carouselList.length > 0">
        <swiper-slide v-for="(item,index) in carouselList" :key="index">
          <img :src="item.image.img" @click="slideJump(item)" style="width: 100%"/>
        </swiper-slide>
        <div class="swiper-pagination carouse-page" slot="pagination"></div>
      </swiper>
      <div class="recent-meetings" :style="recentMeetTopStyle" ref="meetRef" v-if="recentMeetings && recentMeetings.length > 0">
        <div class="recent-meetings-title">
          近期会议
        </div>
        <div class="recent-meetings-list">
          <div class="recent-meetings-list-cell" v-for="(item, index) in recentMeetings" :key="index">
            <div :class="{ 'recent-meetings-list-cell-title': true, 'hover': item.url }" @click="jumpUrl(item.url, item)">{{ item.title }}</div>
            <div class="recent-meetings-list-cell-time">{{ $moment(item.send_time).format("YYYY-MM-DD") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-two">
      <div class="index-two-left">
        <div class="index-two-left-top">
          <a href="/exchange" class="index-two-left-top-title">国际交流</a>
          <div class="index-two-left-top-content">
            2012 年以来，CF40 与国际顶尖智库、重要国际 组织和财经政要形成了稳定而富有成果的交流机制。论坛已连续十三年与美国彼得
            森国际经济研究所（PIIE）联合举办中美经济学家学术交流活动，并于布鲁金斯学会（Brookings Institution）、欧洲50人论坛
            （Euro50）、日本野村综合研究院（NRI）等国际知名智库开展交流与合作。
          </div>
        </div>
        <div class="index-two-left-list">
          <a href="http://www.bundsummit.org/" target="_blank" class="index-two-left-list-bound">
            <img src="~@/assets/bund_summit.png" />
          </a>
          <a href="/exchange" class="index-two-left-list-more">
            更多国际交流
          </a>
        </div>
      </div>
      <div class="index-two-right">
        <swiper :options="twoSwiperOption" class="index-two-swiper" ref="twoSwiper">
          <swiper-slide v-for="(item,index) in worldList" :key="index">
            <img :src="item.cover_image && item.cover_image.img ? item.cover_image.img : ''" @click="jumpUrl('/article/1/'+item.id, item)" />
            <div class="swiper-desc">
              <div class="swiper-desc-title">
                {{ item.introduction }}
              </div>
              <div class="swiper-desc-time">{{ $moment(item.send_time).format("YYYY年MM月DD日") }}</div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev index-swiper-prev" slot="button-prev"></div>
          <div class="swiper-button-next index-swiper-next" slot="button-next"></div>
        </swiper>
        <div class="index-two-right-bg"></div>
      </div>
    </div>
    <div class="index-three">
      <el-divider>
        <img src="~@/assets/cf40-study.png" />
      </el-divider>
      <div class="index-three-content">
        <div class="index-three-content-left">
          <div class="index-three-content-left-title">简报</div>
          <div class="index-three-content-left-list">
            <div :class="{ 'index-three-content-left-list-cell': true, 'active': item.id == selectBriefing }" v-for="(item, index) in briefingList" :key="index">
              <div class="index-three-content-left-list-cell-header" @mouseover="selectBriefing = item.id">
                <a :href="'https://40com.muyiapp.com/report/chinese/'+item.id" target="_blank" class="index-three-content-left-list-cell-header-title">{{ item.product_name }}</a>
                <div class="index-three-content-left-list-cell-header-desc">
                  <div class="tag">{{ $moment(item.publish_time).format("YYYY-MM-DD") }}</div>
                  <div class="tag">{{ item.author }}</div>
                </div>
              </div>
              <div class="index-three-content-left-list-cell-content">
                {{ removeHtmlTags(item.content) }}
              </div>
            </div>
          </div>
        </div>
        <div class="index-three-content-right">
          <div class="index-three-content-right-tab">
            <div :class="{ 'index-three-content-right-tab-cell': true, 'active': reportClass == 18 }" @click="getAnyReport(18)">锐评</div>
            <div :class="{ 'index-three-content-right-tab-cell': true, 'active': reportClass == 17 }" @click="getAnyReport(17)">参考</div>
          </div>
          <div class="index-three-content-right-list">
            <div class="index-three-content-right-list-cell" v-for="(item, index) in anyReports" :key="index">
              <a :href="'https://40com.muyiapp.com/report/chinese/'+item.id" target="_blank" class="index-three-content-right-list-cell-title">{{ item.product_name }}</a>
              <div class="index-three-content-right-list-cell-desc">
                <div class="tag">{{ $moment(item.publish_time).format("YYYY-MM-DD") }}</div>
                <div class="tag">{{ item.author }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-four">
      <el-divider>
        <div class="index-four-title">学术观点</div>
      </el-divider>
      <div class="index-four-content">
        <div class="index-four-content-cell" v-for="(item, index) in learnList" :key="index">
          <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="index-four-content-cell-title">{{ item.title }}</a>
          <div class="index-four-content-cell-desc">{{ item.introduction }}</div>
          <div class="index-four-content-cell-label">
            <div class="tag">{{ $moment(item.send_time).format("YYYY-MM-DD") }}</div>
            <div class="tag">{{ item.author }}</div>
          </div>
          <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="index-four-content-cell-point">
            <i class="el-icon-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="index-five">
      <div class="index-five-header">
        <div class="index-five-header-title">出版物</div>
        <div class="index-five-header-tab">
          <div :class="{ 'index-five-header-tab-cell': true, 'active': publicationType == 25 }" @click="getPublications(25)">中英文书籍</div>
          <div :class="{ 'index-five-header-tab-cell': true, 'active': publicationType == 26 }" @click="getPublications(26)">音视频节目</div>
        </div>
      </div>
      <div class="index-five-content">
        <swiper :options="fiveSwiperOption" class="index-five-swiper">
          <swiper-slide v-for="(item,index) in publicationList" :key="index">
            <div :class="{ 'slide-item': true, 'active': selectPublication == item.id }" @mouseover="selectPublication = item.id">
              <a :href="createUrl('/book/'+item.id, item)" class="s_book" target="_blank" v-if="publicationType == 25">
                <div class="icon">
                  <img :src="item.litpic && item.litpic.img ? item.litpic.img : ''"/>
                </div>
                <div class="title">
                  {{ item.name }}
                </div>
              </a>
              <a :href="createUrl('/article/1/'+item.id, item)" class="s_article" target="_blank" v-else>
                <div class="icon">
                  <img :src="item.cover_image && item.cover_image.img ? item.cover_image.img : ''"/>
                </div>
                <div class="title">
                  {{ item.title }}
                </div>
              </a>
            </div>
          </swiper-slide>
          <div class="swiper-button-next index-five-next" slot="button-prev"></div>
          <div class="swiper-button-prev index-five-prev" slot="button-next"></div>
        </swiper>
      </div>
    </div>
    <div class="index-six">
      <div class="index-six-title">理事单位</div>
      <div class="index-six-content">
        <swiper :options="sixSwiperOption" class="index-six-swiper">
          <swiper-slide v-for="(item,index) in groupedCompanyList" :key="index">
            <div class="company-group">
              <div v-for="(jtem, jndex) in item" :key="jndex" :class="{ 'company-item': true, 'active': jtem.link }" @click="jumpUrl(jtem.link, jtem.target)">
                <img :src="jtem.icon && jtem.icon.img ? jtem.icon.img : ''" />
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-next index-six-next" slot="button-prev"></div>
          <div class="swiper-button-prev index-six-prev" slot="button-next"></div>
        </swiper>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import SwiperMixin from "./swiperMixin";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import { slideList, articleList, friendLinks, reportList } from "@/api";
export default {
  name: "Index",
  mixins: [SwiperMixin],
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      loading: false,
      // 轮播图数据
      carouselList: [],
      // 近期会议
      recentMeetings: [],
      // 局里上边高度
      recentMeetTopStyle: "",
      // 国际交流轮播图
      worldList: [],
      // 简报列表
      briefingList: [],
      // 当前展开描述的简报ID
      selectBriefing: 0,
      // 锐评、参考列表
      anyReports: [],
      // 选中的分类ID
      reportClass: 18,
      // 学术观点列表
      learnList: [],
      // 出版物列表
      publicationList: [],
      // 选中的类型
      publicationType: 25,
      // 鼠标放上去的悬浮ID
      selectPublication: 0,
      // 理事单位列表
      companyList: [],
    }
  },
  created() {
    this.initData();
  },
  updated() {
    this.$nextTick(()=>{
      let swiperHeight = this.$refs.oneSwiper && this.$refs.oneSwiper.$swiper && this.$refs.oneSwiper.$swiper.height ? this.$refs.oneSwiper.$swiper.height : 0;
      let meetHeight = this.$refs.meetRef && this.$refs.meetRef.offsetHeight ? this.$refs.meetRef.offsetHeight : 0;
      if (swiperHeight >  meetHeight) {
        let betweenHeight = (swiperHeight - meetHeight) / 2;
        this.recentMeetTopStyle = "top: " + betweenHeight + "px";
      }
    });
  },
  computed: {
    groupedCompanyList() {
      let itemsPerRow = 6;
      let rowsPerSlide = 5;
      if (window.innerWidth > 1024) {
        // PC端
        itemsPerRow = 6;
        rowsPerSlide = 5;
      } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
        // 平板端
        itemsPerRow = 4;
        rowsPerSlide = 3;
      } else {
        // 手机端
        itemsPerRow = 1;
        rowsPerSlide = 3;
      }
      const itemsPerSlide = itemsPerRow * rowsPerSlide;

      return this.companyList.reduce((acc, curr, index) => {
        const slideIndex = Math.floor(index / itemsPerSlide);
        if (!acc[slideIndex]) {
          acc[slideIndex] = [];
        }
        acc[slideIndex].push(curr);
        return acc;
      }, []);
    }
  },
  methods: {
    // 初始化数据
    async initData() {
      this.loading = true;
      await this.getCarouseList();
      await this.getRecentMeets();
      await this.getExchange();
      await this.getAcademic();
      await this.getPublications(25);
      await this.getCompanyList();
      await this.getBriefing();
      await this.getAnyReport(18);
      this.loading = false;
    },
    // 获取轮播图数据
    async getCarouseList() {
      let res = await slideList({ pageSize: 50, currentPage: 1, orderBy: "sort", sortedBy: "desc", search: "status:1", searchFields: "status:=" });
      if (res && res.code == 200) {
        this.carouselList = res.data.list;
        this.$nextTick(()=>{
          if (this.$refs.oneSwiper) {
            this.$refs.oneSwiper.initSwiper();
          }
        });
      }
    },
    // 获取近期会议
    async getRecentMeets() {
      let res = await articleList(6,{ pageSize: 3, currentPage: 1, orderBy: "sort", sortedBy: "desc", search: "status:1", searchFields: "status:=" });
      if (res && res.code == 200) {
        this.recentMeetings = res.data.list;
      }
    },
    // 获取国际交流轮播图
    async getExchange() {
      let res = await articleList( 1,
          { pageSize: 5,
            currentPage: 1,
            orderBy: "send_time",
            sortedBy: "desc",
            search: "cover_image_id:0|class_id:15|status:1",
            searchFields: "cover_image_id:>|class_id:=|status:="
          });
      if (res && res.code == 200) {
        this.worldList = res.data.list;
        this.$nextTick(() => {
          if (this.$refs.twoSwiper) {
            this.$refs.twoSwiper.initSwiper();
          }
        });
      }
    },
    // 获取学术观点-研究成果中除了CF40研究的内容
    async getAcademic() {
      let res = await articleList( 1,
          { pageSize: 6,
            currentPage: 1,
            orderBy: "send_time",
            sortedBy: "desc",
            search: "class_id:17,18,19,20,21|status:1",
            searchFields: "class_id:in|status:="
          });
      if (res && res.code == 200) {
        this.learnList = res.data.list;
      }
    },
    // 获取出版物
    async getPublications(tid) {
      this.publicationType = tid;
      let modeId = tid == 25 ? 5 : 1;
      let res = await articleList( modeId,
          { pageSize: 20,
            currentPage: 1,
            orderBy: "send_time",
            sortedBy: "desc",
            search: `class_id:${tid}|status:1`,
            searchFields: "class_id:in|status:="
          });
      if (res && res.code == 200) {
        this.publicationList = res.data.list;
        if (this.publicationList && this.publicationList.length > 0) {
          this.selectPublication = this.publicationList[0]['id'];
        }
      }
    },
    // 获取理事单位
    async getCompanyList() {
      let res = await friendLinks(
          { pageSize: 500,
            currentPage: 1,
            orderBy: "sort",
            sortedBy: "desc",
            search: "link_type:1|status:1",
            searchFields: "link_type:=|status:="
          });
      if (res && res.code == 200) {
        this.companyList = res.data.list;
      }
    },
    // 获取简报报告
    async getBriefing() {
      let param = {
        pageSize: 3,
        currentPage: 1,
        search: "product_type:1|pdf_url_id:0|product_class_id:16",
        searchFields: "product_type:=|pdf_url_id:>|product_class_id:=",
        orderBy: "publish_time",
        sortedBy: "desc"
      };
      let res = await reportList(param);
      if (res && res.code == 200) {
        this.briefingList = res.data.list;
        if (this.briefingList && this.briefingList.length > 0) {
          this.selectBriefing = this.briefingList[0]['id'];
        }
      }
    },
    // 获取锐评、参考报告列表
    async getAnyReport(type) {
      this.reportClass = type;
      let param = {
        pageSize: 4,
        currentPage: 1,
        search: `product_type:1|pdf_url_id:0|product_class_id:${type}`,
        searchFields: "product_type:=|pdf_url_id:>|product_class_id:=",
        orderBy: "publish_time",
        sortedBy: "desc"
      };
      let res = await reportList(param);
      if (res && res.code == 200) {
        this.anyReports = res.data.list;
      }
    },
    // 去除HTML标签
    removeHtmlTags(value) {
      return value ? value.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, '') : "暂无简介";
    },
    // 创建URL链接
    createUrl(url, row) {
      if (row) {
        if (row.jump_link) {
          return row.jump_link;
        } else {
          return url;
        }
      }
    },
    // 跳转
    jumpUrl(url, row) {
      if (row) {
        if (row.jump_link) {
          this.routerLink(row.jump_link, true);
        } else {
          if (url) {
            if (row.hasOwnProperty("new_window")) {
              this.routerLink(url, row.new_window);
            } else {
              this.routerLink(url, true);
            }
          }
        }
      }
    },
    // 轮播图跳转
    slideJump(row) {
      if (row && row.link) {
        this.routerLink(row.link, row.target);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.index {
  .tag {
    &:before {
      content: '|';
      margin: 0 20px;
      color: #a7a7a7;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
  }
  &-one {
    position: relative;
    &-swiper {
      width: 100%;
      cursor: pointer;
      .carouse-page {
        width: auto;
        left: 20%;
        bottom: 10% !important;
        ::v-deep {
          .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            margin: 0 12px;
            background: #d9d9d9;
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            position: relative;
            background: #d9d9d9;
            &:after {
              width: 20px;
              height: 20px;
              content: '';
              position: absolute;
              left: -5px;
              top: -5px;
              border: 1px solid #d9d9d9;
              border-radius: 100%;
            }
          }
        }
      }
      .swiper-slide {
        line-height: 0;
      }
    }
    .recent-meetings {
      position: absolute;
      width: 325px;
      height: auto;
      padding: 50px 30px 30px 30px;
      top: 55px;
      right: 16%;
      z-index: 999;
      color: #1e1e1e;
      background: rgba(255, 255, 255, 0.8);
      &-title {
        font-size: 20px;
        font-family: "HarmonyOS_Bold";
      }
      &-list {
        &-cell {
          font-size: 16px;
          padding: 30px 0;
          border-bottom: 1px solid #b2b2ba;
          &:last-child {
            border: none;
          }
          &-title {
            &.hover {
              cursor: pointer;
            }
          }
          &-time {
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  &-two {
    display: flex;
    justify-content: space-between;
    padding: 120px 10%;
    &-left {
      width: 40%;
      display: flex;
      flex-direction: column;
      &-top {
        &-title {
          font-size: 36px;
          font-family: "HarmonyOS_Bold";
          color: #1e1e1e;
        }
        &-content {
          text-align: justify;
          font-size: 16px;
          margin-top: 50px;
          color: #0b0b0b;
        }
      }
      &-list {
        max-width: 192px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &-more {
          margin-top: 50px;
          color: #ffffff;
          padding: 15px 10px;
          text-align: center;
          background-color: #969696;
          display: inline-block;
        }
      }
    }
    &-right {
      position: relative;
      max-width: 50%;
      .index-two-swiper {
        width: 100%;
        cursor: pointer;
        .swiper-desc {
          width: 80%;
          position: absolute;
          bottom: 40px;
          left: 50px;
          color: #ffffff;
          text-shadow: 2px 2px 2px #000;
          &-title {
            width: 100%;
            font-size: 16px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow:ellipsis;
          }
          &-time {
            margin-top: 20px;
            font-size: 14px;
          }
        }
        .index-swiper-prev,.index-swiper-next {
          width: 10px;
          height: 10px;
          top: auto;
          color: #ffffff !important;
          &:after {
            font-size: 20px;
          }
        }
        .index-swiper-prev {
          right: 10% !important;
          left: auto;
          bottom: 50px;
        }
        .index-swiper-next {
          bottom: 50px;
          right: 5% !important;
        }
      }
      &-bg {
        position: absolute;
        left: -50px;
        top: 20%;
        width: 70%;
        height: 70%;
        background-color: #f2f2f7;
      }
    }
  }
  &-three {
    padding: 50px 5%;
    &-content {
      padding: 50px 10%;
      display: flex;
      justify-content: space-between;
      &-left {
        width: calc(60% - 40px);
        &-title {
          font-size: 36px;
          font-family: "HarmonyOS_Bold";
        }
        &-list {
          &-cell {
            margin-top: 50px;
            &-header {
              &-title {
                color: #0b0b0b;
                font-size: 16px;
                font-family: "HarmonyOS_Bold";
              }
              &-desc {
                margin-top: 10px;
                color: #a7a7a7;
                display: flex;
                align-items: center;
                font-size: 14px;
              }
            }
            &-content {
              margin-top: 30px;
              color: #a7a7a7;
              font-size: 15px;
              line-height: 1.6;
              -webkit-line-clamp:4; //指定显示行数
              -webkit-box-orient: vertical; //文本垂直排列
              overflow: hidden;  //多余部分隐藏
              display: none;
            }
            &.active {
              .index-three-content-left-list-cell-content {
                display: -webkit-box;
              }
            }
          }
        }
      }
      &-right {
        width: calc(40% - 30px);
        &-tab {
          font-size: 36px;
          color: #999999;
          display: flex;
          align-items: center;
          &-cell {
            padding-left: 30px;
            cursor: pointer;
            &:first-child {
              padding-left: 0;
            }
            &.active {
              font-family: "HarmonyOS_Bold";
              color: #1e1e1e;
            }
          }
        }
        &-list {
          padding-top: 25px;
          &-cell {
            padding: 25px 0;
            border-bottom: 1px solid #d3d3d3;
            &:last-child {
              border: none;
            }
            &-title {
              cursor: pointer;
              font-size: 16px;
              font-family: "HarmonyOS_Bold";
            }
            &-desc {
              margin-top: 10px;
              color: #a7a7a7;
              display: flex;
              align-items: center;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  &-four {
    padding: 50px 5% 100px 5%;
    &-title {
      font-size: 40px;
      font-family: "HarmonyOS_Bold";
      padding: 0 30px;
    }
    &-content {
      padding: 30px 10%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &-cell {
        position: relative;
        width: 31%;
        padding: 50px 2.33% 50px 0;
        color: #0b0b0b;
        &:nth-child(3n) {
          padding-right: 0;
        }
        &-title {
          font-size: 16px;
          font-family: "HarmonyOS_Bold";
        }
        &-desc {
          font-size: 14px;
          margin-top: 20px;
          text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制为6行 */
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-label {
          margin-top: 10px;
          color: #a7a7a7;
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        &-point {
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 30px;
          height: 30px;
          cursor: pointer;
          border-radius: 30px;
          border: 1px solid #ebebeb;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f7f7f7;
          &:hover {
            background-color: #ebebeb;
          }
          i {
            color: #ed753c;
          }
        }
      }
    }
  }
  &-five {
    padding: 50px 5% 80px 5%;
    background-color: #f2f2f7;
    &-header {
      color: #1e1e1e;
      padding: 30px 10%;
      display: flex;
      justify-content: space-between;
      &-title {
        font-size: 40px;
        font-family: "HarmonyOS_Bold";
      }
      &-tab {
        color: #9f9f9f;
        display: flex;
        align-items: end;
        &-cell {
          cursor: pointer;
          font-size: 20px;
          margin-left: 30px;
          &:first-child {
            margin-left: 0;
          }
          &.active {
            color: #1e1e1e;
          }
        }
      }
    }
    &-content {
      padding: 30px 10% 0 10%;
      .index-five-swiper {
        padding-bottom: 90px;
        .slide-item {
          .s_book, .s_article {
            text-align: center;
            display: block;
            .icon {
              display: flex;
              align-items: end;
              justify-content: center;
              overflow: hidden;
            }
            .title {
              margin: 0 auto;
              font-size: 16px;
              padding-top: 20px;
            }
          }
          .s_book {
            .icon {
              img {
                display: block;
                transform: scale(0.8);
                transition: transform 0.3s ease;
              }
            }
          }
          &:hover,&.active {
            .s_book, .s_article {
              .title {
                font-family: "HarmonyOS_Bold";
              }
            }
            .s_book {
              .icon {
                img {
                  transform: scale(1);
                }
              }
            }
          }
        }
        .index-five-prev, .index-five-next {
          width: 30px;
          height: 30px;
          background-color: #ffffff;
          border: 1px solid #ececed;
          border-radius: 50%;
          &:after {
            font-size: 12px;
            color: #ee7840;
          }
          &.swiper-button-disabled {
            opacity: 1 !important;
            cursor: pointer !important;
            pointer-events: auto !important;
          }
          &:hover {
            color: #ffffff;
            background: #ee7840;
            &:after {
              color: #ffffff;
            }
          }
        }
        .index-five-prev, .index-five-next {
          top: auto;
          bottom: 0px;
          z-index: 9999;
        }
        .index-five-prev {
          left: 46%;
        }
        .index-five-next {
          right: 46%;
        }
      }
    }
  }
  &-six {
    padding: 50px 5% 80px 5%;
    &-title {
      font-size: 38px;
      text-align: center;
      font-family: "HarmonyOS_Bold";
    }
    &-content {
      margin-top: 50px;
      padding: 30px 10%;
    }
    &-swiper {
      padding: 0 50px;
      .index-six-prev, .index-six-next {
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border: 1px solid #ececed;
        border-radius: 50%;
        &:after {
          font-size: 12px;
          color: #ee7840;
        }
        &.swiper-button-disabled {
          opacity: 1 !important;
          cursor: pointer !important;
          pointer-events: auto !important;
        }
        &:hover {
          color: #ffffff;
          background: #ee7840;
          &:after {
            color: #ffffff;
          }
        }
      }
    }
    .company-group {
      padding-top: 10px;
      display: flex;
      flex-wrap: wrap;
    }
    .company-item {
      position: relative;
      width: 15%;
      margin-right: 2%;
      margin-bottom: 30px;
      &:nth-child(6n) {
        margin-right: 0;
      }
      text-align: center;
      img {
        display: block; /* 使得图片充满容器 */
        width: 100%; /* 图片宽度设置为容器宽度 */
        height: auto; /* 图片高度自动调整 */
        transition: transform 0.3s ease; /* 平滑的放大缩小效果 */
      }
      &.active {
        cursor: pointer;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: transform 0.3s ease; /* 平滑的阴影放大缩小效果 */
      }
      &:hover:before, &:hover img {
        transform: scale(1.1); /* 鼠标悬停时阴影放大 */
        box-shadow: 0px 1px 5px 1px #dcdcdc;
      }
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .index {
    &-one {
      .recent-meetings {
        width: 260px;
        top: 15%;
        right: 10%;
        padding: 30px;
        &-list {
          &-cell {
            font-size: 16px;
            padding: 15px 0;
          }
        }
      }
    }
    &-two {
      &-left {
        &-top {
          &-title {
            font-size: 30px;
          }
          &-content {
            margin-top: 30px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .index {
    &-one {
      position: relative;
      &-swiper {
        width: 100%;
        cursor: pointer;
        .carouse-page {
          width: auto;
          left: 20%;
          bottom: 10% !important;
          ::v-deep {
            .swiper-pagination-bullet {
              width: 10px;
              height: 10px;
              margin: 0 12px;
              background: #d9d9d9;
              opacity: 1;
            }
            .swiper-pagination-bullet-active {
              position: relative;
              background: #d9d9d9;
              &:after {
                width: 18px;
                height: 18px;
                content: '';
                position: absolute;
                left: -5px;
                top: -5px;
                border: 1px solid #d9d9d9;
                border-radius: 100%;
              }
            }
          }
        }
      }
      .recent-meetings {
        position: static;
        width: calc(100% - 60px);
        height: auto;
        z-index: 999;
        color: #1e1e1e;
        background: rgba(255, 255, 255, 0.8);
      }
    }
    &-two {
      padding: 50px 30px;
      display: block;
      &-left {
        width: 100%;
        padding: 0;
        &-top {
          &-title {
            font-size: 28px;
          }
          &-content {
            margin-top: 30px;
          }
        }
        &-list {
          max-width: 100%;
          margin-top: 15px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          a {
            line-height: 0;
          }
          &-more {
            margin-top: 0;
            padding: 30px;
          }
        }
      }
      &-right {
        margin-top: 30px;
        max-width: 100%;
        .index-two-swiper {
          .swiper-desc {
            left: 30px;
            bottom: 30px;
          }
        }
        &-bg {
          display: none;
        }
      }
    }
    &-three {
      padding: 30px;
      &-content {
        padding: 0;
        display: block;
        &-left, &-right {
          width: 100%;
          padding: 30px 0 0 0;
        }
      }
    }
    &-four {
      padding: 100px 30px;
      &-title {
        font-size: 32px;
      }
      &-content {
        padding: 30px 0;
      }
    }
    &-five {
      padding: 50px 30px;
      &-header {
        padding: 30px 0;
        &-title {
          font-size: 32px;
        }
        &-tab {
          &-cell {
            font-size: 18px;
          }
        }
      }
      &-content {
        padding: 30px 0;
        .index-five-swiper {
          .slide-item {
            .img {
              width: 100%;
              height: auto;
            }
            .title {
              width: 100%;
            }
          }
          .index-five-prev {
            left: 43%;
          }
          .index-five-next {
            right: 43%;
          }
        }
      }
    }
    &-six {
      padding: 50px 30px;
      &-title {
        font-size: 30px;
      }
      &-content {
        padding: 30px 0;
      }
      .company-item {
        width: 25%;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .index {
    &-one {
      position: relative;
      &-swiper {
        width: 100%;
        cursor: pointer;
        .carouse-page {
          width: auto;
          left: 20%;
          bottom: 10% !important;
          ::v-deep {
            .swiper-pagination-bullet {
              width: 8px;
              height: 8px;
              margin: 0 10px;
              background: #d9d9d9;
              opacity: 1;
            }
            .swiper-pagination-bullet-active {
              position: relative;
              background: #d9d9d9;
              &:after {
                width: 16px;
                height: 16px;
                content: '';
                position: absolute;
                left: -5px;
                top: -5px;
                border: 1px solid #d9d9d9;
                border-radius: 100%;
              }
            }
          }
        }
      }
      .recent-meetings {
        position: static;
        width: calc(100% - 60px);
        height: auto;
        padding-top: 30px;
        z-index: 999;
        color: #1e1e1e;
        background: rgba(255, 255, 255, 0.8);
        &-title {
          padding-bottom: 15px;
        }
        &-list {
          &-cell {
            padding: 15px 0;
          }
        }
      }
    }
    &-two {
      padding: 30px;
      display: block;
      &-left {
        width: 100%;
        padding: 0;
        &-top {
          &-title {
            font-size: 28px;
          }
          &-content {
            margin-top: 30px;
          }
        }
        &-list {
          max-width: 100%;
          margin-top: 15px;
          a {
            line-height: 0;
          }
          &-more {
            padding: 30px;
          }
        }
      }
      &-right {
        margin-top: 30px;
        max-width: 100%;
        .index-two-swiper {
          .swiper-desc {
            width: 90%;
            left: 15px;
            bottom: 15px;
            &-title {
              font-size: 14px;
              width: 100%;
            }
            &-time {
              margin-top: 5px;
              font-size: 12px;
            }
          }
          .index-swiper-prev {
            right: 20% !important;
            bottom: 20px;
          }
          .index-swiper-next {
            right: 8% !important;
            bottom: 20px;
          }
        }
        &-bg {
          display: none;
        }
      }
    }
    &-three {
      padding: 30px;
      &-content {
        padding: 0;
        display: block;
        &-left, &-right {
          width: 100%;
          padding: 50px 0 0 0;
        }
        &-left {
          &-title {
            font-size: 30px;
          }
        }
        &-right {
          &-tab {
            font-size: 30px;
          }
        }
      }
    }
    &-four {
      padding: 100px 30px;
      &-title {
        font-size: 28px;
        padding: 0;
      }
      &-content {
        padding: 30px 0;
        &-cell {
          width: 100%;
          padding: 50px 0;
          &-desc {
            display: inline-block;
          }
        }
      }
    }
    &-five {
      padding: 50px 30px 20px 30px;
      &-header {
        padding: 30px 0;
        &-title {
          font-size: 28px;
        }
        &-tab {
          &-cell {
            font-size: 16px;
            margin-left: 20px;
          }
        }
      }
      &-content {
        padding: 30px 0;
        .index-five-swiper {
          .slide-item {
            .img {
              width: 100%;
              height: auto;
            }
            .title {
              width: 100%;
              font-size: 14px;
            }
          }
          .index-five-prev {
            left: 35%;
          }
          .index-five-next {
            right: 35%;
          }
        }
      }
    }
    &-six {
      padding: 50px 30px;
      &-title {
        font-size: 30px;
      }
      &-content {
        padding: 30px 0;
      }
      .company-item {
        width: 100%;
      }
    }
  }
}
</style>
